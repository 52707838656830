import { Controller } from '@hotwired/stimulus';
import debounce from 'lodash.debounce';
export default class extends Controller {
  static targets = [
    'form',
    'output',
    'name',
    'id',
    'code',
    'parentIds',
    'division1',
    'division2',
    'division3',
    'division4',
    'division5',
    'division6',
    'division7',
    'division8',
    'division9',
    'searchForm',
    'parentDivision',
    'formImport',
  ];
  static values = {
    url: String,
    urlValidate: String,
    urlDivisions: String,
  };

  initialize() {
    gon.parentIds = {};
    this.handleChange = debounce(this.handleChange, 500).bind(this);
  }

  onHideModal() {
    this.resetForm();
  }

  handleSelectFile() {
    $('#file').trigger('click');
  }

  submitFile(e) {
    if (e.target.files.length === 0) return;
    this.sendRequest();
  }

  onClickEditButton(e) {
    $('#departmentFormLabel.modal-title').text('部署の編集');
    $('#department-form #submit-form').text('保存する');
    const divisionId = e.target.dataset.division;
    Rails.ajax({
      url: this.urlValue + `/${divisionId}/edit`,
      type: 'GET',
      success: (data) => {
        this.onOpenEditForm(data);
      },
      error: () => {
        alert('エラーが発生しました。');
      },
    });
  }

  onClickDeleteButton(e) {
    if (!confirm('本当に削除しますか？')) return;

    const divisionId = e.target.dataset.division;
    Rails.ajax({
      url: this.urlValue + `/${divisionId}`,
      type: 'DELETE',
      success: () => {
        window.location.href = this.urlValue;
      },
      error: () => {
        alert('エラーが発生しました。');
      },
    });
  }

  onOpenEditForm(data) {
    this.setParentIds(data);
    this.nameTarget.value = data.division.name;
    this.codeTarget.value = data.division.code;
    this.idTarget.value = data.division.id;
    $(`option[value="${data.division.id}"]`).remove();

    data.select_options.forEach((so, index) => {
      this.replaceOptions(this[`division${index + 2}Target`], so.options);
      if (this[`division${index + 1}Target`]) {
        $(this[`division${index + 1}Target`]).attr('disabled', false);
        this[`division${index + 1}Target`].value = so.id;
      }
    });
    this.setNextDivisionValue(data);
    $('#department-form').modal('show');
  }

  setParentIds(data) {
    if (data.division.parent_division_id) {
      const parentIds = data.division.parent_division_id.split('/');
      const parentIdByLevel = {};
      parentIds.forEach((p, index) => (parentIdByLevel[index + 1] = p));
      gon.parentIds = parentIdByLevel;
    } else {
      gon.parentIds = {};
    }
  }

  setNextDivisionValue(data) {
    try {
      if (data.select_options.length > 0) {
        const nextDivision = data.select_options.length + 1;
        if (!nextDivision) return;
        this[`division${nextDivision}Target`].value = data.division.id;
      }
    } catch (_error) {}
  }

  submitForm(event) {
    event.preventDefault();
    const button = $(event.target);
    button.attr('disabled', true);

    const formData = new FormData(this.formTarget);
    const parentIds = Object.keys(gon.parentIds)
      .map((id) => gon.parentIds[id])
      .join('/');
    formData.append('divisions_base_form[parent_division_id]', parentIds);
    const divisionId = this.idTarget.value;

    Rails.ajax({
      url: divisionId ? `${this.urlValue}/${divisionId}` : this.urlValue,
      type: divisionId ? 'PATCH' : 'POST',
      data: formData,
      success: (data) => {
        $('.btn-cancel').trigger('click');
        window.location.href = this.urlValue;
      },
      error: (data) => {
        if (data.status === 500) {
          window.location.href = this.urlValue;
        } else {
          Object.keys(data.errors).forEach((err) => {
            const dataHtml = data.errors[err].join('<br />');
            $(`.${err}--error`).html(dataHtml);
            $(`.${err}--error`).removeClass('d-none');
          });
          setTimeout(() => {
            button.attr('disabled', false);
          }, 1000);
        }
      },
    });
  }

  resetForm() {
    this.formTarget.reset();
    $(`.name--error`).addClass('d-none');
    $(`.code--error`).addClass('d-none');
    this.idTarget.value = '';
    this.replaceOptions(this.division1Target, gon.root_divisions);
    for (let i = 2; i < 10; i++) {
      $(this[`division${i}Target`]).attr('disabled', true);
    }
    $('#departmentFormLabel.modal-title').text('部署の追加');
    $('#department-form #submit-form').text('追加する');
  }

  handleChange(event) {
    const formData = new FormData(this.formTarget);
    const parentIds = Object.keys(gon.parentIds)
      .map((id) => gon.parentIds[id])
      .join('/');
    formData.append('form_validation', true);
    formData.append('divisions_base_form[parent_division_id]', parentIds);
    Rails.ajax({
      url: this.urlValue,
      type: 'POST',
      data: formData,
      success: () => {
        const serverFieldName = event.target.dataset.name;
        if (serverFieldName == undefined) {
          $(`.name--error`).addClass('d-none');
        }
        $(`.${serverFieldName}--error`).addClass('d-none');
      },
      error: (data) => {
        const errors = data.errors[event.target.dataset.name];
        if (!errors || errors.length === 0) return;

        const serverFieldName = event.target.dataset.name;
        if (serverFieldName == undefined) {
          serverFieldName = 'name';
        }
        const dataHtml = errors.join('<br />');
        $(`.${serverFieldName}--error`).html(dataHtml);
        $(`.${serverFieldName}--error`).removeClass('d-none');
      },
    });
  }

  updateParentIds(event, level) {
    gon.parentIds[level] = event.target.value;
    if (!event.target.value) {
      for (let index = 4; index >= level; index--) {
        delete gon.parentIds[index];
      }
    }
  }

  onDivision1Change(event) {
    this.updateParentIds(event, 1);
    this.disableOptions(event, 2);
    this.handleChange(event);
  }

  onDivision2Change(event) {
    this.updateParentIds(event, 2);
    this.disableOptions(event, 3);
  }

  onDivision3Change(event) {
    this.updateParentIds(event, 3);
    this.disableOptions(event, 4);
  }

  onDivision4Change(event) {
    this.updateParentIds(event, 4);
    this.disableOptions(event, 5);
  }

  onDivision5Change(event) {
    this.updateParentIds(event, 5);
    this.disableOptions(event, 6);
  }

  onDivision6Change(event) {
    this.updateParentIds(event, 6);
    this.disableOptions(event, 7);
  }

  onDivision7Change(event) {
    this.updateParentIds(event, 7);
    this.disableOptions(event, 8);
  }

  onDivision8Change(event) {
    this.updateParentIds(event, 8);
    this.disableOptions(event, 9);
  }

  onDivision9Change(event) {
    this.updateParentIds(event, 9);
  }

  disableOptions(event, level) {
    let url = '';
    if (level - 1 === 1) {
      url = `${gon.division_url}?parent_division_id=${event.target.value}&id=${this.idTarget.value}`;
    } else {
      url = `${gon.division_url}?parent_division_id=${event.target.value}`;
    }
    Rails.ajax({
      url: url,
      type: 'GET',
      success: (data) => {
        this.replaceOptions(this[`division${level}Target`], data);
        if (level != 9) {
          for (let i = level; i < 10; i++) {
            $(this[`division${i + 1}Target`]).empty();
            $(this[`division${i + 1}Target`]).attr('disabled', true);
          }
        }
      },
    });
  }

  replaceOptions(elementSelect, options) {
    $(elementSelect).empty();
    $(elementSelect).attr('disabled', false);

    $(elementSelect).append(
      $('<option>', {
        text: '',
        value: '',
      })
    );
    options.forEach((division) =>
      $(elementSelect).append(
        $('<option>', {
          text: division.name,
          value: division.id,
        })
      )
    );
  }

  appendChildrenDivision(e) {
    const division = e.currentTarget;
    const divisionId = division.dataset.divisionid;
    const divisionLevel =
      division.dataset.divisionslevel === undefined ? 1 : parseInt(division.dataset.divisionslevel) + 1;
    const root_division = $('.' + divisionId);

    if (root_division.length === 0 && !division.className.split(' ').includes('active')) {
      Rails.ajax({
        url: `${this.urlValue}/${divisionId}?level=${divisionLevel}`,
        type: 'GET',
        success: (data) => {
          if (data['data'] === ' ') return;
          const el = document.createElement('div');
          el.innerHTML += data['data'];
          division.parentElement.parentElement.append(el);
        },
      });
    }

    if (root_division.length === 0) return;

    if (root_division[0].style.display === 'block') {
      root_division.css('display', 'none');
    } else if (root_division[0].style.display === 'none') {
      root_division.css('display', 'block');
    } else {
      alert('エラーが発生しました。');
    }
  }

  sendRequest() {
    var formData = new FormData(this.formImportTarget);
    $('#loader-data').removeClass('hidden');
    Rails.ajax({
      url: this.urlValidateValue,
      type: 'POST',
      contentType: 'application/json',
      data: formData,
      success: function (data) {
        $('.import-data-info-wrapper').html(data);
        $('#import-data-info').addClass('show');
        $('#import-data-info').show();
      },
      complete: function () {
        // Set our complete callback, adding the .hidden class and hiding the spinner.
        $('#loader-data').addClass('hidden');
      },
      errors: function (data) {},
    });
  }

  submitData(e) {
    e.currentTarget.classList.add('disabled');
    this.handleProgressSubmit(e);
  }
  cancelSubmit(e) {
    localStorage.clear();
    location.reload();
  }

  handleProgressSubmit(e) {
    e.preventDefault();
    var mydata = new FormData(this.formImportTarget);
    var total_data = $('.btn-primary').attr('data-import-total');
    Rails.ajax({
      url: this.urlDivisionsValue,
      type: 'POST',
      contentType: 'application/json',
      data: mydata,
      success: (data) => {
        if (total_data > 500) {
          alert(
            'CSV処理が完了次第メールで完了通知を行います。それまでの間に新しいCSVをアップロードされますと、重複登録が発生するため、ご利用をお控えください。'
          );
        }
        $('#import-data-info').removeClass('show');
        $('#import-data-info').hide();
        if (data.length != '') {
          $('.import-data-info-wrapper').html(data);
          $('#import-data-info').addClass('show');
          $('#import-data-info').show();
        } else {
          localStorage.clear();
          location.reload();
        }
      },
      errors: () => {
        localStorage.clear();
        location.reload();
      },
    });
  }
}
