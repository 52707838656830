import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputFile', 'spanNameFile', 'formImportDivision', 'formImportEmployee'];
  static values = {
    urlValidateAssociateEmployee: String,
    urlAssociateEmployee: String,
    urlValidateAssociateDivision: String,
    urlAssociateDivision: String,
  };

  handleSelectFileDivision() {
    $('#fileDivision').trigger('click');
  }

  submitFileDivision(e) {
    if (e.target.files.length === 0) return;
    if (confirm('全件削除してよろしいでしょうか？')) {
      var form = 'formImportDivisionTarget';
      var url = this.urlValidateAssociateDivisionValue;
      localStorage.setItem('formTarget', form);
      localStorage.setItem('urlValue', this.urlAssociateDivisionValue);
      this.sendRequest(form, url);
    } else {
      $('#fileDivision').val(null);
    }
  }

  handleSelectFileEmployee() {
    $('#fileEmployee').trigger('click');
  }

  submitFileEmployee(e) {
    if (e.target.files.length === 0) return;
    if (confirm('全件削除してよろしいでしょうか？')) {
      var form = 'formImportEmployeeTarget';
      var url = this.urlValidateAssociateEmployeeValue;
      localStorage.setItem('formTarget', form);
      localStorage.setItem('urlValue', this.urlAssociateEmployeeValue);
      this.sendRequest(form, url);
    } else {
      $('#fileEmployee').val(null);
    }
  }

  sendRequest(form, url) {
    var formData = new FormData(this[form]);
    $('#loader-data').removeClass('hidden');
    Rails.ajax({
      url: url,
      type: 'POST',
      contentType: 'application/json',
      data: formData,
      success: () => {
        $('.import-data-info-wrapper').html(data);
        $('#import-data-info').addClass('show');
        $('#import-data-info').show();
      },
      complete: () => {
        $('#loader-data').addClass('hidden');
      },
      errors: () => {},
    });
  }

  submitData(e) {
    e.currentTarget.classList.add('disabled');
    this.handleProgressSubmit(e);
  }
  cancelSubmit(e) {
    localStorage.clear();
    location.reload();
  }

  handleProgressSubmit(e) {
    e.preventDefault();
    var mydata = new FormData(this[localStorage['formTarget']]);
    var total_data = $('.btn-primary').attr('data-import-total');
    Rails.ajax({
      url: localStorage['urlValue'],
      type: 'POST',
      contentType: 'application/json',
      data: mydata,
      success: (data) => {
        if (total_data > 500) {
          alert(
            'CSV処理が完了次第メールで完了通知を行います。それまでの間に新しいCSVをアップロードされますと、重複登録が発生するため、ご利用をお控えください。'
          );
        }
        if (data.length != '') {
          $('.import-data-info-wrapper').html(data);
          $('#import-data-info').addClass('show');
          $('#import-data-info').show();
        } else {
          localStorage.clear();
          location.reload();
        }
      },
      errors: () => {
        localStorage.clear();
        location.reload();
      },
    });
  }
}
