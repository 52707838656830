import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['formImport', 'formImportEmployee'];
  static values = {
    urlValidate: String,
    urlCorporate: String,
    urlValidateEmployee: String,
    urlEmployeeCorporate: String,
  };

  submitFile(e) {
    if (e.target.files.length === 0) return;
    let form = 'formImportTarget';
    let url = this.urlCorporateValue;
    localStorage.setItem('formTarget', form);
    localStorage.setItem('urlValue', url);
    this.sendRequest();
  }

  handleSelectFile() {
    $('#file').trigger('click');
  }

  sendRequest() {
    var formData = new FormData(this.formImportTarget);
    $('#loader-data').removeClass('hidden');
    Rails.ajax({
      url: this.urlValidateValue,
      type: 'POST',
      contentType: 'application/json',
      data: formData,
      success: function (data) {
        $('.import-data-corporate-info-wrapper').html(data);
        $('#import-data-info').addClass('show');
        $('#import-data-info').show();
      },
      complete: function () {
        // Set our complete callback, adding the .hidden class and hiding the spinner.
        $('#loader-data').addClass('hidden');
      },
      errors: function (data) {},
    });
  }

  submitData(e) {
    e.currentTarget.classList.add('disabled');
    this.handleProgressSubmit(e);
  }
  cancelSubmit(e) {
    localStorage.clear();
    location.reload();
  }

  handleProgressSubmit(e) {
    e.preventDefault();
    var mydata = new FormData(this[localStorage['formTarget']]);
    var total_data = $('.btn-primary').attr('data-import-total');
    Rails.ajax({
      url: localStorage['urlValue'],
      type: 'POST',
      contentType: 'application/json',
      data: mydata,
      success: function (data) {
        if (total_data > 500) {
          alert(
            'CSV処理が完了次第メールで完了通知を行います。それまでの間に新しいCSVをアップロードされますと、重複登録が発生するため、ご利用をお控えください。'
          );
        }
        $('#import-data-info').removeClass('show');
        $('#import-data-info').hide();
        if (data.length != '') {
          $('.import-data-corporate-info-wrapper').html(data);
          $('#import-data-info').addClass('show');
          $('#import-data-info').show();
        } else {
          localStorage.clear();
          location.reload();
        }
      },
      errors: function (data) {
        localStorage.clear();
        location.reload();
      },
    });
  }

  handleSelectFileEmployee() {
    $('#fileEmployee').trigger('click');
  }

  submitFileEmployee(e) {
    if (e.target.files.length === 0) return;
    let form = 'formImportEmployeeTarget';
    let url = this.urlEmployeeCorporateValue;
    localStorage.setItem('formTarget', form);
    localStorage.setItem('urlValue', url);
    this.sendRequestEmployee();
  }

  sendRequestEmployee() {
    var formData = new FormData(this.formImportEmployeeTarget);
    $('#loader-data').removeClass('hidden');
    Rails.ajax({
      url: this.urlValidateEmployeeValue,
      type: 'POST',
      contentType: 'application/json',
      data: formData,
      success: function (data) {
        $('.import-data-employee-info-wrapper').html(data);
        $('#import-data-info').addClass('show');
        $('#import-data-info').show();
      },
      complete: function () {
        $('#loader-data').addClass('hidden');
      },
      errors: function (data) {},
    });
  }
}
